import { analyticsEvent } from '../controllers/analytics-controller';
$(function () {
  var $accordianHeader = $(".accordian-header"),
    $accordianContent = $(".accordian-content"),
    $accordianPanels = $(".accordion-panel"),
    $accordionTilePanels = $(".accordion-tile-panel"),
    isHeaderClicked = false;

  $accordianHeader.on("click", function () {
    var $header = $(this),
      dataInfo = $header.data("info");
    $accordianHeader.toggleClass("open");
    if ($accordianHeader.hasClass("open")) {
      $accordianContent.show();
    } else {
      $accordianContent.hide();
    }
    if (!isHeaderClicked) {
      analyticsEvent($.extend(analytics_data, dataInfo));
    }
    isHeaderClicked = true;
  });

  // accordion for collection component and product directory page
  $accordianPanels.each(function () {
    var $accordianPanel = $(this),
      $accordianHead = $(this).children(".accordion-header");
    $accordianHead.on("click", function () {
      $accordianPanel.toggleClass("accordion-expanded");
    });
  });

  // accordion for help and support page
  $accordionTilePanels.each(function () {
    var $accordionTilePanel = $(this),
      $accordionHead = $(this).children(".accordion-tile-header");

    $accordionHead.on("click", function () {
      $accordionTilePanel.toggleClass("accordion-tile-expanded");
    });
  });
});
